import { observable, action, computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";
import ruleGroupsStore from "./ruleGroupsStore";
import api from "../services/api";

class TextruleStore {
  textrules = [];

  setData(data) {
    this.textrules = data;
  }

  get allTextrules() {
    return this.textrules.slice();
  }

  // this is basically a computed prop with a paramater
  textruleById = createTransformer((id) => {
    return this.textrules.find((textrule) => textrule.id === id);
  });

  /*  API requests */
  create({ createdTextrule }) {
    return api.textrules.create(createdTextrule).then((textrule) => {
      // there is rule_group object that needs to add to ruleGroups then remove
      ruleGroupsStore.addBaseRuleGroup(textrule.rule_group);
      delete textrule.rule_group;
      this.textrules.push(textrule);
      return textrule;
    });
  }

  delete({ deletedTextrule }) {
    return api.textrules.remove(deletedTextrule).then(() => {
      const index = this.textrules.findIndex((textrule) => textrule.id === deletedTextrule.id);
      this.textrules.splice(index, 1);
    });
  }

  updateOne({ updatedTextrule }) {
    return api.textrules.update(updatedTextrule).then((textrule) => {
      const index = this.textrules.findIndex((textruleItem) => textruleItem.id === updatedTextrule.id);
      this.textrules[index] = textrule;
      return textrule;
    });
  }

  constructor() {
    makeObservable(this, {
      textrules: observable,
      setData: action,
      create: action,
      delete: action,
      updateOne: action,
      allTextrules: computed,
      textruleById: action,
    });
  }
}

export default new TextruleStore();

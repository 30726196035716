import React from "react";
import "./JoIcon.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

const svgs = {};
const reqIcons = import.meta.glob('./icons/*.js');
const iconModules = await Promise.all(Object.values(reqIcons).map(icon => icon()));

Object.keys(reqIcons).forEach((path, index) => {
  const name = path.split(".")[1].split("/")[2];
  svgs[name] = iconModules[index];
});

/* COPIED FROM README.md as this can be confusing when done once in a blue moon 
Svgs have been converted to react components with https://react-svgr.com/docs/cli/
To add a new Icon put the svg file into `src/components/common/icons/` then run
`npx @svgr/cli -- src/components/common/icons/my-svg-file.svg > src/components/common/icons/MySvgFile.js` 
Set icon prop like icon={"CommonFileTextSettings"}
*/

const JoIcon = ({ ...props }) => {
  const IconName = svgs[props.icon].default;
  const title = props.title || props.icon;
  const classes = classNames({
    "jo-icon": true,
    "is-large": props.isLarge,
    "has-spacing-left": props.spacing === ("left" || "both"),
    "has-spacing-right": props.spacing === ("right" || "both"),
  });

  return (
    <span className={classes}>
      <IconName title={title} className="jo-icon__svg" />
    </span>
  );
};

JoIcon.propTypes = {
  icon: PropTypes.string,
  spacing: PropTypes.string,
  title: PropTypes.string,
  isLarge: PropTypes.bool
};

export default JoIcon;

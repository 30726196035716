/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import Document from "./pages/document/Document";
import Test from "./pages/test/Test";
import Error from "./pages/error/Error";

// https://blog.logrocket.com/how-react-hooks-can-replace-react-router/
// '/document' will render a test page in local with link back to dashboard (an id is required) otherwise treat as 404
const routes = {
  "/document": () => (import.meta.env.MODE === "development" ? <Test /> : <Error error={404} />),
  "/document/:id": ({ id }) => <Document id={id} />,
  "/error/:error": ({ error }) => <Error error={error} />,
};

export default routes;

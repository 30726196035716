import { observable, action, computed, makeObservable } from "mobx";
import { jwtDecode } from "jwt-decode";

class AuthStore {
  authData = [];

  token = localStorage.getItem("access_token") || null;

  decodedTokenData = {};

  decodeToken(token) {
    const decodedToken = jwtDecode(token);

    const {
      sub: { email, id },
      user_claims: { name, organisation_id, organisation_hubspot_id },
    } = decodedToken || {};

    this.decodedTokenData = {
      email,
      id,
      organizationName: name,
      organizationId: organisation_id,
      organizationHubspotId: organisation_hubspot_id,
    };
  }

  setToken(token) {
    localStorage.setItem("access_token", token);
    this.decodeToken(token);
    this.token = token;
  }

  setPair(key, value) {
    this.authData[key] = value;
  }

  valuesById(id) {
    return this.authData[id];
  }

  get userIsLoggedIn() {
    return !!this.token;
  }

  get showDocusign() {
    const features = this.authData["features"];
    if (features && Object.prototype.hasOwnProperty.call(features, "docusign") && features.docusign) {
      return true;
    } else {
      return false;
    }
  }

  constructor() {
    makeObservable(this, {
      token: observable,
      userIsLoggedIn: computed,
      setToken: action,
      showDocusign: computed,
    });
  }
}

export default new AuthStore();

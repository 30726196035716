import { observable, action, computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";
import ruleGroupStore from "./ruleGroupsStore";
import api from "../services/api";

class RulesStore {
  rules = [];

  setData(data) {
    this.rules = data;
  }

  get allRules() {
    return this.rules.slice();
  }

  // this is basically a computed prop with a paramater
  ruleById = createTransformer((id) => this.rules.find((rule) => rule.id === id));

  /*  API requests */
  create({ createdRule }) {
    return api.rules.create(createdRule).then((rule) => {
      this.rules.push(rule);
      // push created rule Id into its parent rule_group rule array
      ruleGroupStore.addNestedRule(rule.rule_group_id, rule.id);
      return rule;
    });
  }

  delete({ deletedRule }) {
    return api.rules.remove(deletedRule).then((res) => {
      const index = this.rules.findIndex((rule) => rule.id === deletedRule.id);
      this.rules.splice(index, 1);
      ruleGroupStore.deleteNestedRule(deletedRule.rule_group_id, deletedRule.id);
      return res;
    });
  }

  updateOne({ updatedRule }) {
    // parent_group_id remember this re base level / child rule_group relationship
    return api.rules.update(updatedRule).then((rule) => {
      const index = this.rules.findIndex((rule) => rule.id === updatedRule.id);
      this.rules[index] = rule;
      return rule;
    });
  }

  constructor() {
    makeObservable(this, {
      rules: observable,
      setData: action,
      allRules: computed,
      create: action,
      delete: action,
      updateOne: action,
    });
  }
}

export default new RulesStore();

import { observable, action, makeObservable } from "mobx";

class McOptionsStore {
  mcOptions = {};

  setData(data) {
    if (data) {
      data.forEach((o) => {
        this.mcOptions[o.id] = o;
      });
    }
  }

  get allMcOptions() {
    return this.mcOptions.slice();
  }

  optionById(id) {
    return this.mcOptions[id];
  }

  constructor() {
    makeObservable(this, {
      mcOptions: observable,
      setData: action,
    });
  }
}

export default new McOptionsStore();

import React from "react";
import PropTypes from "prop-types";
import { push as Menu } from "react-burger-menu";
import "./Sidebar.scss";
import Close from "./Close.js";
import ErrorBoundary from "../error-boundary/ErrorBoundary";

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.isMenuOpen = this.isMenuOpen.bind(this);
  }

  isMenuOpen = function (state) {
    if (!state.isOpen) {
      this.props.closeSideBarHandler();
      document.getElementById("editor-position").classList.remove("has-sidebar-open");
    } else {
      document.getElementById("editor-position").classList.add("has-sidebar-open");
    }
    return state.isOpen;
  };

  render() {
    const styleOverrides = {
      // We want to unset this, as we want to be able to set it with CSS
      // and use media queries, rather than put an inline style
      bmCrossButton: {
        width: false,
        height: false,
        top: false,
        right: false,
      },
    };

    return (
      <Menu
        styles={styleOverrides}
        {...this.props}
        right
        noTransition
        onStateChange={this.isMenuOpen}
        customCrossIcon={<Close />}
      >
        <ErrorBoundary>
          {this.props.isOpen && this.props.children}
          {/* This was a wrong turn but have left here as a cool eg of modding props.children to have adiitional props */}
          {/* {this.props.isOpen && (
          <div>{React.cloneElement(this.props.children[1], { closeSidebar: this.isMenuOpen })}</div>
        )} */}
        </ErrorBoundary>
      </Menu>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.node,
  closeSideBarHandler: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default SideBar;

// Polyfills for IE11 and stable features for browsers defined in our browserlist (defined in package.json) must be imported first
// for implementation details, see: https://www.npmjs.com/package/react-app-polyfill
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Then we can import everything else
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import * as serviceWorker from "./serviceWorker";
import authStore from "./store/authStore";
import documentStore from "./store/documentStore";
import textrulesStore from "./store/textrulesStore";
import ruleGroupsStore from "./store/ruleGroupsStore";
import rulesStore from "./store/rulesStore";
import constantsStore from "./store/constantsStore";
import mcOptionsStore from "./store/mcOptionsStore";
import recipientsStore from "./store/recipientsStore";
import i18nStore from "./store/i18nStore";
// import Modal from "react-modal";
import { useRoutes, useRedirect } from "hookrouter";
import Error from "./pages/error/Error";
import routes from "./routes";
import InstrumentationProvider from "./components/providers/InstrumentationProvider";

const stores = {
  authStore,
  documentStore,
  textrulesStore,
  recipientsStore,
  ruleGroupsStore,
  rulesStore,
  constantsStore,
  mcOptionsStore,
  i18nStore,
};

function Router() {
  useRedirect("/", "/document");
  const routeResult = useRoutes(routes);
  return routeResult || <Error error={404} />;
}

// Currently not using any modals - subject to change so left in
// Modal.setAppElement("#root");

// Error tracking initialization
if (import.meta.env.MODE === "production" || import.meta.env.MODE === "staging") {
  window.addEventListener("DOMContentLoaded", function () {
    const errorHandler = new window.StackdriverErrorReporter();
    errorHandler.start({
      key: "AIzaSyCdeFFrnVLcJMsuZUj8kFEfjFb0IH137_s",
      projectId: "joseflegal",
      service: "editor",
      version: "1",
      reportUncaughtExceptions: true, // (optional) Set to false to stop reporting unhandled exceptions.
      // disabled: true                     // (optional) Set to true to not report errors when calling report(), this can be used when developping locally.
      // context: {user: 'user1'}           // (optional) You can set the user later using setUser()
    });
  });
}

fetch("/release.json")
  .then((r) => r.json())
  .then((json) => {
    const parsed = Object.assign({}, json);
    console.table(parsed);
  })
  .catch((err) => {
    console.log("Error loading json:", err);
  });

ReactDOM.render(
  <Provider {...stores}>
    <InstrumentationProvider>
      <Router />
    </InstrumentationProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

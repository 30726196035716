import { observable, action, computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";
import api from "../services/api";

class RuleGroupStore {
  ruleGroups = [];

  setData(data) {
    this.ruleGroups = data;
  }

  // this is basically a computed prop with a paramater
  ruleGroupById = createTransformer((id) => this.ruleGroups.find((ruleGroup) => ruleGroup.id === id));

  get allRuleGroups() {
    return this.ruleGroups.slice();
  }

  /*  API requests */
  create({ createdRuleGroup }) {
    return api.rule_groups.create(createdRuleGroup).then((ruleGroup) => {
      this.ruleGroups.push(ruleGroup);
      this.addNestedRuleGroup(createdRuleGroup.parent_group_id, ruleGroup.id);
      return ruleGroup;
    });
  }

  delete({ deletedRuleGroup }) {
    return api.rule_groups.remove(deletedRuleGroup).then(() => {
      // if need be delete id from parent groups rule_groups [] first
      if (deletedRuleGroup.parent_group_id) {
        this.deleteNestedRuleGroup(deletedRuleGroup.parent_group_id, deletedRuleGroup.id);
      }
      const index = this.ruleGroups.findIndex((ruleGroup) => ruleGroup.id === deletedRuleGroup.id);
      this.ruleGroups.splice(index, 1);
    });
  }

  updateOne({ updatedRuleGroup }) {
    // parent_group_id remember this re base level / child rule_group relationship
    return api.rule_groups.update(updatedRuleGroup).then((ruleGroup) => {
      const index = this.ruleGroups.findIndex((ruleGroupItem) => ruleGroupItem.id === updatedRuleGroup.id);
      this.ruleGroups[index] = ruleGroup;
      return ruleGroup;
    });
  }

  /* Mutations */
  addBaseRuleGroup(ruleGroup) {
    // eg when we create a textrule we add its returned baselevel rule group here
    this.ruleGroups.push(ruleGroup);
  }

  // nested rules groups Ids will also be added to BaseRuleGroup rule_groups [] here
  addNestedRuleGroup(parentId, ruleGroupId) {
    const index = this.ruleGroups.findIndex((ruleGroup) => ruleGroup.id === parentId);
    this.ruleGroups[index].rule_groups.push(ruleGroupId);
  }

  deleteNestedRuleGroup(parentId, ruleGroupId) {
    const groupIndex = this.ruleGroups.findIndex((group) => group.id === parentId);
    const index = this.ruleGroups[groupIndex].rule_groups.findIndex((id) => id === ruleGroupId);
    this.ruleGroups[groupIndex].rule_groups.splice(index, 1);
  }

  addNestedRule(id, ruleId) {
    // when a rule is added we need to push the rules id to the parent rule_group rules array
    const index = this.ruleGroups.findIndex((ruleGroup) => ruleGroup.id === id);
    if (!this.ruleGroups[index].rules.includes(ruleId)) {
      this.ruleGroups[index].rules.push(ruleId);
    }
  }

  deleteNestedRule(id, ruleId) {
    // when a rule is deleted we need to delete the rules id from  the parent rule_group rules array
    const ruleGroupIndex = this.ruleGroups.findIndex((ruleGroup) => ruleGroup.id === id);
    const ruleIndex = this.ruleGroups[ruleGroupIndex].rules.findIndex((id) => id === ruleId);
    this.ruleGroups[ruleGroupIndex].rules.splice(ruleIndex, 1);
  }

  constructor() {
    makeObservable(this, {
      ruleGroups: observable,
      allRuleGroups: computed,
      setData: action,
      create: action,
      delete: action,
      updateOne: action,
    });
  }
}

export default new RuleGroupStore();

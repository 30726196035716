function getRegion() {
  if (import.meta.env.MODE !== "development") {
    return window.location.hostname.split(".")[0];
  }
  return "";
}

function getTLD() {
  if (import.meta.env.MODE !== "development") {
    let hostname = window.location.hostname;
    hostname = hostname.slice(hostname.indexOf(".") + 1);
    hostname = hostname.slice(hostname.indexOf(".") + 1);
    return hostname;
  }
  return "";
}
export function getDashboardURL() {
  if (import.meta.env.MODE !== "development") {
    return `https://dashboard.${getTLD()}`;
  }
  return "http://localhost:8888";
}

export function getAPIURL() {
  if (import.meta.env.MODE !== "development") {
    return `https://${getRegion()}.api.${getTLD()}/api`;
  }
  return "http://localhost:8080/api";
}

export function getAuthURL() {
  if (import.meta.env.MODE !== "development") {
    return `https://auth.${getTLD()}/api`;
  }
  return "http://localhost:8081/api";
}

export function getWordURL() {
  if (import.meta.env.MODE !== "development") {
    return `https://${getRegion()}.word.${getTLD()}`;
  }
  return "http://localhost:5000";
}

import { DocusignRecipientsButton } from "./DocusignRecipientsButton";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  ContentState,
  SelectionState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RichUtils,
  Editor,
  DefaultDraftBlockRenderMap,
  CompositeDecorator,
} from "draft-js";

import { toJS } from "mobx";
import { debounce } from "lodash";
import { getSelectedBlocksList, getSelectionInlineStyle } from "draftjs-utils";
import { inject } from "mobx-react";
import Immutable from "immutable";
import "./AutomationEditor.scss";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import ToggleSwitch from "../common/ToggleSwitch";
import { Color } from "../../utils/constants";

import {
  findAnswerEntities,
  AnswerComponent,
  findDocusignTabEntities,
  DocusignTabComponent,
  findTabEntities,
  TabComponent,
  findXRefEntities,
  XRefComponent,
  findLinkEntities,
  LinkComponent,
} from "./decorators";

import { Inline, standardStyleMap, customStyleMap, ExportFormat, EntityTypes, BlockType } from "../../utils/constants";
// Import global helpers
import { isListItem, dlog, LEVEL, debouncePromise } from "../../utils/helpers";
import { gaEvent } from "../../utils/analytics";

// Import editor specific funcs/helpers;
import {
  getState,
  customBlockRenderer,
  getBlockFromDom,
  hasInlineStyleInRange,
  getNotDeletedBlockBefore,
  getNotDeletedBlockAfter,
  hasActiveBlock,
  focusedBlocksHasChanged,
  changeBlockDataForUnlocked,
  getIsAnswerBtnDisabled,
  blockContentHasChanged,
  duplicateBlock,
  moveUpBlock,
  moveDownBlock,
  getIsMoveBtnDisabled,
  unlinkTextrule,
} from "./lib/editor";
import { insertEntity } from "./lib/entities";
import {
  getSelectionHasTextrule,
  getSelectedInlineTextruleData,
  insertInlineTextrule,
  insertBlockTextrule,
  getIsBlockTextrule,
  isShouldBlockLevel,
  getIsInlineTextrule,
  getIsTextruleBtnDisabled,
  hasInlineTextrule,
  applyTextruleActiveState,
  resetTextruleStyleInBlock,
  updateInlineTextrules,
  deleteEmptyTextrule,
} from "./lib/textrules";
import {
  getIsInlineStylesBtnDisabled,
  blockStyleCopy,
  blockStylePaste,
  blockStyleCopyIsBtnDisabled,
  blockStyleCopyIsBtnActive,
} from "./lib/inline-styles";
import { markLoopingBlock, getIsLoopBtnDisabled } from "./lib/looping";
import {
  keyBindingFn,
  handleSelectAll,
  handleSoftReturns,
  handleTab,
  handleBeforeInput,
  handleCopy,
  handlePaste,
  handleCut,
} from "./lib/key-commands";
import { expandTableSelection, getAllTableCellsInRow, shouldTableCellSelected } from "./lib/table";
import { detectBrowser } from "./lib/helpers";
import { isUnsupportedBlock, unlockBlock, updateUnsupportedCount, goToFirstUnsupportedBlock } from "./lib/unsupported";

// import Services and helpers
// NOTE editorHelpers will be phased out Held on to for now but look to delete these helpers if not ultimated used (TB 25/11/19)
// import { removeEntity, removeAllEntities } from "./editorHelpers";

// import components
import Navbar from "../navbar/Navbar";
import NavbarGroup from "../navbar/NavbarGroup";
import JoButton from "../common/JoButton.js";
import JoLink from "../common/JoLink.js";
import JoIcon from "../common/JoIcon.js";
import JoToolbarButton from "../common/JoToolbarButton.js";
import InsertAnswer from "./InsertAnswer";
import BaseRuleGroup from "./BaseRuleGroup";
import BlockMenu from "../block-menu/BlockMenu";
import Toolbar from "../toolbar/Toolbar";
import SideBar from "../common/Sidebar.js";
import Table from "../table/Table";
import Banner from "../common/Banner";
import Preloader from "../common/Preloader";
import JoTooltip from "../common/JoTooltip";
import JoPopupMenu from "../common/JoPopupMenu";
import UnlockBlockModal from "../modal/UnlockBlockModal";
import CtaMessageBar from "../common/CtaMessageBar";
import Logo from "../common/Logo";
import JoSelectableCards from "../common/JoSelectableCards.js";

// Adds basic styling to things like lists
import "draft-js/dist/Draft.css";
import DebugInfo from "../debug-info/DebugInfo";
import { getWordURL, getDashboardURL } from "../../utils/url";
import DocuSignRecipientSidebar from "./DocuSignRecipientSideBar";

import api from "../../services/api/";

// These are ripped out of draft-js source code thay can be useful for logging data or future needs
// eg  perhaps we need a more efficient convertFromDraftStateToRaw to doesnt proccess entire document
// const convertFromDraftStateToRaw = require("../../utils/draft-utils/convertBlockToRaw");
// const encodeInlineStyleRanges = require("../../utils/draft-utils/encodeInlineStyleRanges");

/* Various helper functions that are candidates for moving outside this file into helper files when they are more stable */

// keep support for other draft default block types and add our myCustomBlock type
// the blockRenderMap allows us to extend Draft.js block types for example Word has 'Title' which in recat we will render as a h1
const blockRenderMap = Immutable.Map({
  // move to utils/constants when more established
  Title: {
    element: "h1",
  },
  "header-one": {
    element: "h2",
  },
  "header-two": {
    element: "h3",
  },
  "header-three": {
    element: "h4",
  },
  "header-four": {
    element: "h5",
  },
  "header-five": {
    element: "h6",
  },
  Table: {
    wrapper: detectBrowser().name !== "ie" ? <Table /> : null, // table wrappers currently crash in ie
  },
  "unordered-list-item": {
    element: "div",
  },
  "ordered-list-item": {
    element: "div",
  },
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const exportFormatOptions = Object.values(ExportFormat).map((x) => x);

class AutomationEditor extends Component {
  constructor(props) {
    super(props);
    if (!import.meta.env.DEV) {
      console.log(this.props.data);
    }

    const textrules = props.textrulesStore.allTextrules;
    const dynamicTextrulesStyles = {};
    for (let i = 0; i < textrules.length; i++) {
      dynamicTextrulesStyles[`TEXTRULE_${textrules[i].id}`] = customStyleMap.TEXTRULE;
      dynamicTextrulesStyles[`TEXTRULEACTIVE_${textrules[i].id}`] = customStyleMap.TEXTRULE_ACTIVE;
    }

    const styles = {
      ...standardStyleMap,
      TEXTRULE: customStyleMap.TEXTRULE,
      TEXTRULE_ACTIVE: customStyleMap.TEXTRULE_ACTIVE,
      ...dynamicTextrulesStyles,
    };

    this.state = {
      editorState: EditorState.createWithContent(
        convertFromRaw(toJS(this.props.data)),
        new CompositeDecorator(this.generateDecorators())
      ),
      focusedBlocks: null,
      changedBlocks: [],
      focusedBlockElement: null,
      unsupportedCount: 0,
      hasFocus: false,
      creatingTextrule: false,
      selectedTextruleId: null,
      selectedLoop: false,
      sideBarOpen: false,
      sideBarContent: null,
      toolbar: this.resetToolbar(),
      isSaving: false,
      saveButton: {
        icon: "CheckCircle1",
        text: "Saved",
      },
      isSaved: true,
      browser: {
        name: "unknown",
        os: "unknown",
        version: "unknown",
      },
      customStyleMap: styles,
      clipboard: {
        key: null, // Source Block Key
      },
      modal: {
        unlockBlock: false,
      },
      // Document table columns
      name: "",
      documentFormatId: this.props.documentFormatId,
      sendToDocusign: false,
      expires: false,
      DocuSignRecipientKey: 0, // Is used to force react to rerender when docusign switch is changed 🙄
    };
    this.clipboard = {
      text: null,
      answers: [
        // {
        //  key: entityKey of answer
        //  offset: the offset of answer entity inside the clipboard text
        // }
      ],
      inlineStyles: [
        // {
        //  styles: inlineStyles of a character
        //  offset: the offset inside the clipboard text
        // }
      ],
    };

    dlog(LEVEL.DEBUG, 0, "Editor first state=", this.state);

    this.setDomEditorRef = (ref) => (this.domEditor = ref);

    // manually bind handlers because React
    this.handleCloseSideBar = this.handleCloseSideBar.bind(this);
    this.onInsertAnswer = this.onInsertAnswer.bind(this);
    this.onInsertDocusignTab = this.onInsertDocusignTab.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);
    this.onRedoClick = this.onRedoClick.bind(this);
    this.onMoveBlock = this.onMoveBlock.bind(this);
    this.onDeleteBlock = this.onDeleteBlock.bind(this);
    this.onDuplicateBlock = this.onDuplicateBlock.bind(this);
    this.onMoveDownBlock = this.onMoveDownBlock.bind(this);
    this.onUnlockModalShow = this.onUnlockModalShow.bind(this);
    this.onUnlockModalClose = this.onUnlockModalClose.bind(this);
    this.onUnlockBlock = this.onUnlockBlock.bind(this);
    this.customBlockStyleFn = this.customBlockStyleFn.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClickRule = this.onClickRule.bind(this);
    this.onClickLoop = this.onClickLoop.bind(this);
    this.onInsertLoop = this.onInsertLoop.bind(this);
    this.onDeleteLoop = this.onDeleteLoop.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.updateEditorState = this.updateEditorState.bind(this);
    this.handlePastedText = this.handlePastedText.bind(this);
    this.onDeleteTextrule = this.onDeleteTextrule.bind(this);
    this.onUnlinkTextrule = this.onUnlinkTextrule.bind(this);
    this.onWindowClose = this.onWindowClose.bind(this);
    this.handleToggleExportFormat = this.handleToggleExportFormat.bind(this);
    this.onGoToFirstUnsupportedBlock = this.onGoToFirstUnsupportedBlock.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.handleOpenSideBar = this.handleOpenSideBar.bind(this);
    this.handleSendToDocusign = this.handleSendToDocusign.bind(this);
    this.handleExpire = this.handleExpire.bind(this);
    this.updateDocumentTable = this.updateDocumentTable.bind(this);
    this.debouncedUpdate = this.debouncedUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentFormatId !== this.props.documentFormatId) {
      this.setState({
        documentFormatId: this.props.documentFormatId,
      });
    }
    ReactTooltip.rebuild();
  }

  /* React Lifecycle hooks */

  componentDidMount() {
    // detect browser for storing in state
    const browser = detectBrowser();

    // focus 1st block of editor
    this.domEditor.focus();
    this.setState({
      name: this.props.documentStore.name,
      sendToDocusign: this.props.documentStore.send_to_docusign,
      expires: this.props.documentStore.expires,
      hasFocus: true,
      browser,
    });
    window.addEventListener("beforeunload", this.onWindowClose);

    // give us a global classname that tells us the browser being used
    document.getElementsByTagName("body")[0].classList.add("is-browser-" + browser.name);

    console.log("----- docusignStatus -------------");
    console.log(this.props.recipientsStore.docusignStatus);

    if (window.zE && window.zE.setHelpCenterSuggestions) {
      window.zE.setHelpCenterSuggestions({ labels: ["editor"] });
    }
  }

  generateDecorators() {
    // decorators find items in entityMap in blocks (strategy) and use component to render them differently
    // so Answer will be put in a span with its own classes for styling
    const decorators = [
      {
        strategy: findAnswerEntities,
        component: AnswerComponent,
        props: {
          focusAnswer: this.focusEntity.bind(this),
        },
      },
      {
        strategy: findDocusignTabEntities,
        component: DocusignTabComponent,
        props: {
          focusDocusignTab: this.focusEntity.bind(this),
        },
      },
      {
        strategy: findTabEntities,
        component: TabComponent,
        props: {
          focusTab: this.focusEntity.bind(this),
        },
      },
      {
        strategy: findXRefEntities,
        component: XRefComponent,
        props: {
          focusXRef: this.focusEntity.bind(this),
        },
      },
      {
        strategy: findLinkEntities,
        component: LinkComponent,
        props: {
          focusLink: this.focusEntity.bind(this),
        },
      },
    ];
    return decorators;
  }

  onWindowClose(e) {
    // if the current state of the editor isn't saved, then show a warning message about unsaved changes
    if (this.state.isSaved !== true) {
      var confirmationMessage = "o/";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome etc.
    }
  }

  /* draft-js Editor component methods */

  customBlockStyleFn(contentBlock) {
    const debugOn = import.meta.env.VITE_APP_DEBUG === "true";

    // first we will check if the block is deleted — we only need to output one class if so
    if (contentBlock.getIn(["data", "is_deleted"])) {
      return debugOn ? "deleted" : "hidden";
    }

    // if not deleted, we will build up a list of relevant classes to return here
    const classes = [];

    // checking for focus on block
    // first check multiple selection
    const focusKeys = this.state.focusedBlocks;
    const isInFocusRange =
      contentBlock.type === "Table"
        ? shouldTableCellSelected(contentBlock, focusKeys, this.state.editorState)
        : focusKeys && focusKeys.includes(contentBlock.getKey());
    // any kind of focus, including single or multiple selection
    const isFocused = this.state.hasFocus && isInFocusRange;

    if (debugOn) {
      if (contentBlock.getIn(["data", "source_id"])) {
        classes.push("duplicated");
      }
    }

    if (contentBlock.type === "Table") {
      classes.push("table__cell");
      // Now we use the percentage to set the width of each table cell and table itself.
      // The table itself has 100% width.
      // So here, we calculate the width percentage of each table cell.
      const cellWidth = contentBlock.getIn(["data", "width"]);
      const tableWidth = contentBlock.getIn(["data", "tableWidth"]);
      const maxColumns = contentBlock.getIn(["data", "maxColumns"]);
      // These are set to the nearest 1% and flex-grow and flex-shrink props we set deal with rounding pretty well

      // In fact, we should check if width-type is `auto`.
      // Anyway, if width value is 0, we should auto-size widths.
      let widthPercent = Math.floor(100 / maxColumns);
      if (tableWidth !== 0 && cellWidth !== 0) {
        widthPercent = Math.floor((cellWidth / tableWidth) * 100);
      }

      classes.push("table-cell-width-" + widthPercent);

      // tables don't yet work in ie — crashes browser
      if (this.state.browser.name === "ie") {
        classes.push("unsupported-content");
      }

      if (contentBlock.getIn(["data", "is_looping_block"])) {
        classes.push("is-looping-block-table");
      }
      // "fake" blocks (that can't be selected) need a class and some special handling
      if (contentBlock.getData().getIn(["is_fake"])) {
        classes.push("is-fake");

        // they only get a selected class if included in a *multiple* selection
        const isMultiFocus = focusKeys && focusKeys.length > 1;
        // ie: fake blocks can't be selected on thier own
        if (isInFocusRange && isMultiFocus) {
          classes.push("selected-block");
        }
      } else if (isFocused) {
        // if not fake, they can have a selected class if focused
        classes.push("selected-block");
      }
    } else if (isFocused) {
      // if not table we can add a simple selected class if focused
      classes.push("selected-block");
    }

    if (contentBlock.getIn(["data", "unsupported_content"])) {
      classes.push("unsupported-content");
    }

    const listItem = contentBlock.getIn(["data", "listItem"]);

    /*
      A block's list format is empty.
      Then draftjs built-in list-style is displayed.
      So we should remove draftjs built-in list-style.
    */
    classes.push("list-style-none");

    if (isListItem(contentBlock)) {
      let depth = contentBlock.depth;
      if (
        listItem !== undefined &&
        listItem.isRestartNumbering &&
        // We should not rely on the copied restarting info
        !contentBlock.getIn(["data", "style_source_id"])
      ) {
        var start = listItem.start - 1;
        classes.push("list-restart-level-" + depth + "-at-" + start);
      } else {
        depth += 1;
        classes.push("list-restart-level-" + depth + "-at-0");
      }

      // lists don't yet work in ie — crashes browser
      if (this.state.browser.name === "ie") {
        classes.push("unsupported-content");
      }
    }
    if (contentBlock.getIn(["data", "textrule_id"])) {
      const tId = contentBlock.getIn(["data", "textrule_id"]);
      const textrule = this.props.textrulesStore.textruleById(tId);
      if (textrule) {
        // Temporary disabled until https://trello.com/c/IFYRLOn8/383-2editing-textrules3 is solved for inline_textrules
        const textruleClasses = classNames({
          textblock: true,
          "textblock--active": tId === this.state.selectedTextruleId,
        });
        classes.push(textruleClasses);
      }
    }

    if (contentBlock.getIn(["data", "is_looping_block"])) {
      classes.push("is-looping-block");
    }

    // block_classes will be an empty string or alignment classes eg align-right
    if (contentBlock.getIn(["data", "block_classes"])) {
      classes.push(contentBlock.getIn(["data", "block_classes"]));
    }

    if (classes.length) {
      return classes.join(" ");
    }
  }

  isUnsupportedOrFake(block = null) {
    if (this.props.isDisabled) {
      return true;
    }

    if (block) {
      const data = block.getData();
      return data.get("is_fake") || data.get("unsupported_content");
    }
    // look into some props on our selected blocks to determine if a block is disabled
    if (!this.state.focusedBlocks) {
      return false;
    }
    const { contentState } = getState(this.state.editorState);
    // when we arrive in the func as a result of inline style change or content change there can only be one focused block
    const blockByKey = contentState.getBlockForKey(this.state.focusedBlocks[0]);
    return blockByKey.getIn(["data", "is_fake"]) || blockByKey.getIn(["data", "unsupported_content"]);
  }

  // https://draftjs.org/docs/advanced-topics-key-bindings
  handleKeyCommand(command) {
    // If we aren't even focused on the editor these commands should execute:
    // Possible problem with editor can be focused with no block focused
    if (!this.state.hasFocus && (Object.prototype.hasOwnProperty.call(Inline, command) || command === "select-all")) {
      return "handled";
    }

    // Exit immediately if we are inside a "fake" table cell or an unsupported content block
    if (this.isUnsupportedOrFake()) {
      return "handled";
    }

    const editorState = this.state.editorState;
    const { contentState, selectionState, selectionIsCollapsed, selectionStart } = getState(editorState, false, true);
    const blockByKey = this.state.focusedBlocks ? contentState.getBlockForKey(this.state.focusedBlocks[0]) : null;
    const blockTextLength = blockByKey && blockByKey.text.length;

    // handle BOLD, ITALIC etc
    if (Object.prototype.hasOwnProperty.call(Inline, command)) {
      this.handleInlineStyleChange(RichUtils.toggleInlineStyle(this.state.editorState, command));
      return "handled";
    }

    // handle reload
    switch (command) {
      case "RELOAD":
        console.log("RELOAD switch");
        window.location.reload();
        break;
      case "select-all":
        handleSelectAll.call(this);
        return "handled";
      case "soft-return":
        handleSoftReturns.call(this);
        this.triggerSave();
        return "handled";
      case "tab":
        handleTab.call(this);
        this.triggerSave();
        return "handled";
      case "backspace":
        // prevent block deletion or merging when backspace/delete is pressed at beginning of block
        if (
          (selectionIsCollapsed && selectionStart === 0) ||
          selectionState.getAnchorKey() !== selectionState.getFocusKey()
        ) {
          return "handled";
        }
        break;
      case "delete":
        // prevent delete button when we are at the very end of block (otherwise it will combine blocks)
        // this includes empty blocks
        if (
          (selectionIsCollapsed && blockTextLength === selectionStart) ||
          selectionState.getAnchorKey() !== selectionState.getFocusKey()
        ) {
          return "handled";
        }
        break;
      case "copy":
        handleCopy.call(this);
        return "handled";
      case "cut":
        handleCut.call(this);
        return "handled";
      case "escape": {
        const clipboard = this.state.clipboard;
        if (clipboard.key) {
          this.setState({ clipboard: { ...clipboard, key: null } });
          document.getElementById("App").style.cursor = "";
        }
        break;
      }
      default:
        break;
    }

    // let draft-js default behaviour take effect
    return "not-handled";
  }

  onFocus() {
    this.domEditor.focus();
    this.setState({ hasFocus: true });
  }

  onBlur() {
    this.setState({ hasFocus: false });
  }

  // this is supplied as a prop to answer Entity so clicks on the answer Component can force an the focus to update
  focusEntity(blockKey, entityOffset) {
    // create a new selectionState using supplied blockKey from entity comp and force and update to editorState with it
    const newSelectionState = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: entityOffset,
      focusKey: blockKey,
      focusOffset: entityOffset,
      hasFocus: true,
      isBackward: false,
    });
    const updatedEditorState = EditorState.forceSelection(this.state.editorState, newSelectionState);
    /**
     * selectedTextruleId will be changed by this new selectionState
     */
    this.onChange(updatedEditorState);
  }
  // Used by onChange and other actions to update state. modifiedBlockKey will be added to state and used in onSave
  // to update is_modified then reset after save
  updateEditorState({
    newEditorState = this.state.editorState,
    updateSidebar = true,
    focusedBlocks = this.state.focusedBlocks,
    hasFocus = true,
    selectedTextruleId = this.state.selectedTextruleId,
    selectedLoop = this.state.selectedLoop,
    changedBlocks = this.state.changedBlocks,
  }) {
    // Get existing UIState (Sidebars etc). If more than one block selected reset Toolbar
    let possibleStyles = getSelectionInlineStyle(newEditorState);
    newEditorState
      .getCurrentInlineStyle()
      .toJS()
      .forEach((s) => (possibleStyles[s] = true));
    const UIState = {
      sideBarContent: this.state.sideBarContent,
      sideBarOpen: this.state.sideBarOpen,
      toolbar: focusedBlocks && focusedBlocks.length === 1 ? possibleStyles : this.resetToolbar(),
    };

    // We need to check whether the sidebar should be closed, and close it
    // in the future we may want to call updateEditorState without effecting sidebar
    // so first check updateSidebar paramater
    if (updateSidebar) {
      // check whether we need to close the sidebar for text rules or loops
      const shouldCloseSidebarTextRule = !selectedTextruleId && UIState.sideBarContent === "textrule";
      const shouldCloseSidebarLoop = !selectedLoop && UIState.sideBarContent === "loop";
      const shouldCloseSidebarSettings = UIState.sideBarContent === "settings";

      // close the sidebar
      if (shouldCloseSidebarTextRule || shouldCloseSidebarLoop || shouldCloseSidebarSettings) {
        UIState["sideBarContent"] = "";
        UIState["sideBarOpen"] = false;
      }
    }

    // set state updates, and then trigger focus update (which requires state to have updated the DOM already)
    this.setState(
      {
        editorState: newEditorState,
        focusedBlocks,
        hasFocus,
        selectedTextruleId: selectedTextruleId ? selectedTextruleId : null,
        selectedLoop,
        changedBlocks,
        ...UIState,
      },
      this.updateFocus
    );
  }

  // this function just updates the focus from the state
  // because it reaches intothe DOM we usually need to run this on a callback,
  // after editorState has been updated in the DOM
  updateFocus() {
    const focusedBlockElement = this.state.focusedBlocks
      ? getBlockFromDom(this.state.focusedBlocks[0], this.state.editorState)
      : null;

    this.setState({
      focusedBlockElement,
    });
  }

  // <Editor/> prop: onChange function is called when edits and selection changes occur in the editor
  // is used to figure out whats changing and make actual changes to state with updateEditorState()
  // Unless you are dealing with changed content or user selection changes you should bypass this and call
  // updateEditorState() directly if you want to change AutomationEditor state object
  onChange = (newEditorState, changedBlocks = this.state.changedBlocks) => {
    let { contentState: newContentState, selectionState: newSelectionState } = getState(newEditorState);
    let { contentState: currentContentState } = getState(this.state.editorState);
    const { selectedTextruleId: oldSelectedTextruleId } = this.state;

    const contentHasChanged = currentContentState !== newContentState;
    let selectedBlocks;
    let selectedBlockCount = 0;

    // blur changes to state get wiped by immediate call to onChange
    const editorHasBlurred = !newSelectionState.getHasFocus();

    // If the sidebar is already open and editor has blurred exit!
    if (editorHasBlurred && this.state.sideBarOpen) {
      return;
    }

    // 1. is_modified flag
    const selBlockChanged = blockContentHasChanged(newEditorState, this.state.editorState);
    if (selBlockChanged) {
      const blockData = Immutable.Map({ is_modified: true });
      newEditorState = changeBlockDataForUnlocked(newEditorState, newContentState, newSelectionState, blockData);
      newContentState = newEditorState.getCurrentContent();
    }

    // 2. selectionState
    selectedBlocks = getSelectedBlocksList(newEditorState).filter(
      // filter out any deleted blocks — these will have is_deleted: true
      (block) => block && block.getIn(["data", "is_deleted"]) === false
    );

    selectedBlockCount = selectedBlocks.count();

    // check if we have multiple table cells selected, and if so, expand selection to the whole row
    if (selectedBlockCount > 1 && selectedBlocks && selectedBlocks.getIn([0, "type"]) === "Table") {
      const { tableSelectionState, selectedTableBlocks } = expandTableSelection(selectedBlocks, newEditorState, 0);
      newSelectionState = tableSelectionState ? tableSelectionState : newSelectionState;
      selectedBlocks = selectedTableBlocks ? selectedTableBlocks : selectedBlocks;
    }

    // This will prevent Chrome expanding selection to next block on 3 clicks in block
    if (selectedBlockCount === 2 && newSelectionState.get("focusOffset") === 0) {
      selectedBlocks = selectedBlocks.pop();
      selectedBlockCount--;
      newSelectionState = new SelectionState({
        anchorKey: newSelectionState.anchorKey,
        anchorOffset: newSelectionState.anchorOffset,
        focusKey: newSelectionState.anchorKey, // ignore the focusKey at the next block and move it to the 1st block
        focusOffset: selectedBlocks.toJS()[0].text.length, // focusOffset is 0 at the next block and change it to the 1st block's last position
        hasFocus: true,
        isBackward: false,
      });
    }

    // if sidebar has opened for answer we need ignore editorHasBlurred and preserve focusedBlocks currently in state
    // double ternary selectedBlockCount gives us null in case of 0
    // selectedBlocks should always be an Immutable List we convert it here toJS as it will be put into this.state.focusedBlocks
    // which we dont need to be Immutable - at this stage
    let focusedBlocks =
      this.state.sideBarContent === "answer"
        ? this.state.focusedBlocks
        : selectedBlockCount && selectedBlocks
        ? selectedBlocks.toJS().map((block) => {
            return block.key;
          })
        : null;

    if (selBlockChanged && focusedBlocks) {
      changedBlocks.push(...focusedBlocks);
      changedBlocks = [...new Set(changedBlocks)];
    }

    const blockKey = focusedBlocks ? focusedBlocks[0] : null;
    // We should make sure `block` is always the updated one from the latest/updated editorState.
    // So in fact, we'd better use `blockKey` as parameter instead of `block`
    // then each function should get `block` from the latest/updated editorState by itself.
    let blockByKey = blockKey ? newContentState.getBlockForKey(blockKey) : null;
    const oldBlockByKey = this.state.focusedBlocks
      ? currentContentState.getBlockForKey(this.state.focusedBlocks[0])
      : null;
    const oldBlockHadInlineTextrules = hasInlineTextrule(oldBlockByKey);

    // We also need to change our state if our selected block has looping applied
    const isLoopingBlock = blockKey && !!blockByKey.getIn(["data", "is_looping_block"]);

    // 3. inline textrule offset
    // If content has changed in a single focused block we need may need to change blocks data
    if (contentHasChanged && focusedBlocks !== null && focusedBlocks.length === 1) {
      // new inline_tetxrules solution 💪
      if (hasInlineTextrule(blockByKey)) {
        newEditorState = updateInlineTextrules(newEditorState, blockByKey);
        newContentState = newEditorState.getCurrentContent();
      }
    }

    // 4. textrule style at the old block
    /*  if user has navigated to another block remove inline_textrule TEXTRULE_ACTIVE from previously focused block if need be */
    if (
      oldBlockByKey &&
      oldBlockHadInlineTextrules &&
      focusedBlocksHasChanged(focusedBlocks, this.state.focusedBlocks)
    ) {
      newEditorState = resetTextruleStyleInBlock(newEditorState, newSelectionState, oldBlockByKey.getKey());
      newContentState = newEditorState.getCurrentContent();
    }

    // 5. textrule style at the focused block
    // returns textrule Id if any in current selection or 0 if not
    let selectedTextruleId = editorHasBlurred ? null : getSelectionHasTextrule(blockByKey, newSelectionState);
    if (oldSelectedTextruleId !== selectedTextruleId && oldSelectedTextruleId) {
      const oldBlockKey = this.state.focusedBlocks ? this.state.focusedBlocks[0] : null;
      deleteEmptyTextrule.call(this, oldSelectedTextruleId, oldBlockKey);
    }

    // toggle TEXTRULE_ACTIVE in current block if has inline_textrules and selectedTextrule is inline
    if (hasInlineTextrule(blockByKey)) {
      // We should reset all TEXTRULE_ACTIVE style first.
      newEditorState = resetTextruleStyleInBlock(newEditorState, newSelectionState, blockKey);
      if (selectedTextruleId) {
        const textruleIsInline = getIsInlineTextrule(blockByKey, selectedTextruleId);
        if (textruleIsInline) {
          const selectedTextrule = blockByKey
            .getIn(["data", "inline_textrules"])
            .filter((textrule) => textrule.id === selectedTextruleId)[0];

          newEditorState = applyTextruleActiveState(newEditorState, blockKey, selectedTextrule);
        }
      }
    }

    // currently we always forceSelection so block .selected class will be applied (do this after all the other funcs as selectionState has been likely altered)
    newEditorState = EditorState.forceSelection(newEditorState, newSelectionState);

    this.updateEditorState({
      newEditorState,
      focusedBlocks,
      hasFocus: !editorHasBlurred,
      selectedTextruleId,
      selectedLoop: isLoopingBlock,
      changedBlocks,
    });
    if (this.state.clipboard.key) {
      blockStylePaste.call(this, focusedBlocks);
    }
    updateUnsupportedCount.call(this, newEditorState);
    // when selectionState changes, we don't have to call API
    if (contentHasChanged) {
      this.triggerSave();
    }
  };

  // we are intercepting the default paste behaviour because we want content to paste in the context of a single block
  // the default beahviour would instead create a lot of new blocks
  handlePastedText(/*text, html, editorState*/) {
    handlePaste.call(this);
    return true;
  }

  // set all inline style buttons (BOLD etc) to off
  resetToolbar() {
    return Object.keys(Inline).reduce((acc, next) => {
      acc[next] = false;
      return acc;
    }, {});
  }

  /* Navigation toolbar (main actions) event handlers */
  getInsertTextruleBtnLabel() {
    const { selectionState, contentState } = getState(this.state.editorState);

    // first need to get the relevant block (or first block from a multiple selection)
    const textrule = this.state.selectedTextruleId
      ? this.props.textrulesStore.textruleById(this.state.selectedTextruleId)
      : null;
    const blockByKey = this.state.focusedBlocks ? contentState.getBlockForKey(this.state.focusedBlocks[0]) : null;

    // determine if its got a block level rule or not
    const isBlockLevel = blockByKey && textrule ? getIsBlockTextrule(blockByKey, textrule.id) : false;
    const isShouldBlock = isShouldBlockLevel(this.state.focusedBlocks, contentState, selectionState);
    const isTable = blockByKey && blockByKey.get("type") === BlockType.Table ? true : false;

    // The condition for the text on add and edit needs to be slightly different
    const editTextRuleType = textrule && isBlockLevel ? (isTable ? "row" : "block") : "text";
    const addTextRuleType = isShouldBlock ? (isTable ? "row" : "block") : "text";
    const action = this.props.isDisabled ? "View" : "Edit";
    // figure out our final text to display
    return this.state.selectedTextruleId && textrule
      ? `${action} ${editTextRuleType} rule`
      : `Add ${addTextRuleType} rule`;
  }

  /* Navigation toolbar (main actions) event handlers */
  getInsertLoopBtnLabel() {
    return this.state.selectedLoop ? "Edit looping" : "Apply looping";
  }

  handleOpenSideBar(content) {
    this.setState({ sideBarOpen: true, sideBarContent: content, hasFocus: false });
  }
  handleCloseSideBar() {
    deleteEmptyTextrule.call(
      this,
      this.state.selectedTextruleId,
      this.state.focusedBlocks ? this.state.focusedBlocks[0] : null
    );
    this.setState({ sideBarOpen: false, sideBarContent: null, hasFocus: true });
  }

  onClickRule() {
    this.handleOpenSideBar("textrule");

    // This handles click the rule button begins textrule creation or opens textrule sidebar depending on selection
    if (!this.state.selectedTextruleId || !this.props.textrulesStore.textruleById(this.state.selectedTextruleId)) {
      this.onInsertTextrule();
    }
  }

  onClickLoop() {
    // If not already a loop, then insert a loop
    if (!this.state.selectedLoop) {
      this.onInsertLoop();
    }
    // whether we are adding or editing a loop, we always want to show the sidebar
    this.handleOpenSideBar("loop");
  }

  onSaveEditor = (saveData) => {
    let logIndent = 0;
    dlog(LEVEL.DEBUG, logIndent, "onSaveEditor ...");
    console.log("onSaveEditor", this.state.changedBlocks);
    logIndent++;

    this.setState({
      isSaving: true,
      saveButton: {
        icon: "NavigationMenuHorizontal",
        text: "Saving",
      },
      isSaved: false,
    });

    const contentState = this.state.editorState.getCurrentContent();
    const data = convertToRaw(contentState);

    dlog(LEVEL.DEBUG, logIndent, "onSaveEditor data=", data);

    return this.props
      .updateDocument(data, saveData, logIndent)
      .then(() => {
        this.setState({
          isSaving: false,
          saveButton: {
            icon: "CheckCircle1",
            text: "Saved",
          },
          isSaved: true,
          changedBlocks: [],
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isSaving: false,
          saveButton: {
            icon: "AlertCircle",
            text: "Saving failed",
          },
          isSaved: false,
        });
      });
  };

  onPreview = (formatId) => {
    let url = getWordURL();
    url += "/" + this.props.documentUuid + `/preview?document_format_id=${formatId}`;

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Actions",
      action: "Export",
      label: url,
    });

    window.open(url, "_blank");
  };

  handleBackToDashboard = () => {
    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Navigation",
      action: "Back to Dashboard",
    });
    return true;
  };

  debouncedUpdate = debouncePromise(this.updateDocumentTable, 1000, this);

  handleUpdateName = (e) => {
    this.limitedUpdateDocument({ name: e.target.value });
    this.setState({
      name: e.target.value,
    });
  };

  handleToggleExportFormat(indexArray) {
    if (indexArray.length) {
      const format = exportFormatOptions[indexArray[0]].id;
      this.setState({
        documentFormatId: format,
      });

      this.limitedUpdateDocument({
        document_format_id: format,
      });
    }
  }

  handleSendToDocusign() {
    const value = !this.state.sendToDocusign;

    this.setState({
      sendToDocusign: value,
    });

    this.debouncedUpdate({ send_to_docusign: value }).then(() => {
      if (value) {
        api.documents
          .getEnvelopeTemplate({ id: this.props.documentId })
          .then((res) => {
            console.log(res);
            this.props.recipientsStore.setData(res.envelopeTemplate.data.envelope_template);
            this.props.recipientsStore.setDocusignStatus(true);
            this.setState({
              DocuSignRecipientKey: this.DocuSignRecipientKey + 1,
            });
          })
          .catch((err) => {
            this.props.recipientsStore.setDocusignStatus(false);
          });
      } else {
        this.props.recipientsStore.resetData();
        this.props.recipientsStore.setDocusignStatus(false);
        this.setState({
          DocuSignRecipientKey: this.DocuSignRecipientKey + 1,
        });
      }
    });
  }

  handleExpire() {
    const value = !this.state.expires;

    this.setState({
      expires: value,
    });

    this.debouncedUpdate({ expires: value });
  }

  updateDocumentTable(updatedDocument) {
    if (this.state.name) {
      return this.props.documentStore.update(updatedDocument);
    }
  }

  // Using this for non draft js related updates on the document eg name, expires etc
  limitedUpdateDocument = debounce((updatedDocument) => {
    if (this.state.name) {
      this.props.documentStore.update(updatedDocument);
    }
  }, 1000);

  // Send all the outputs of RichUtils.toggleInlineStyle() here so we can handle updateEditorState in one place and update this.state.modifiedStylesBlocks
  // which is used to update is_styles_modified flag on blocks
  // Note that inserting custom style for inline textrule should be considered as is_styles_modified=true
  // and it needs isSave=false because they do it by themselves
  handleInlineStyleChange(editorState, isSave = true) {
    const blockData = Immutable.Map({ is_styles_modified: true });
    const { contentState, selectionState } = getState(editorState);
    const newEditorState = changeBlockDataForUnlocked(editorState, contentState, selectionState, blockData);
    if (isSave) {
      let changedBlocks = this.state.changedBlocks;
      changedBlocks.push(this.state.focusedBlocks[0]);
      changedBlocks = [...new Set(changedBlocks)];

      this.updateEditorState({ newEditorState, changedBlocks });
      this.triggerSave();
    }
    return newEditorState;
  }

  /* Inline Toolbar event handlers */
  onBoldClick = (/* e */) => {
    this.handleInlineStyleChange(RichUtils.toggleInlineStyle(this.state.editorState, Inline.BOLD));
  };

  onItalicClick = () => {
    this.handleInlineStyleChange(RichUtils.toggleInlineStyle(this.state.editorState, Inline.ITALIC));
  };

  onUnderlineClick = () => {
    this.handleInlineStyleChange(RichUtils.toggleInlineStyle(this.state.editorState, Inline.UNDERLINE));
  };

  onCapsClick = () => {
    this.handleInlineStyleChange(RichUtils.toggleInlineStyle(this.state.editorState, Inline.CAPS));
  };

  onCopyBlockStyleClick = () => {
    blockStyleCopy.call(this);
  };

  onUndoClick() {
    this.onChange(EditorState.undo(this.state.editorState));
  }

  onRedoClick() {
    this.onChange(EditorState.redo(this.state.editorState));
  }

  /* Floating block toolbar event handlers */

  onMoveBlock(direction) {
    // will not work correctly on multiple selected blocks
    let contentState = this.state.editorState.getCurrentContent();
    let blocks = contentState.getBlocksAsArray();
    const index = blocks.findIndex((item) => item.get("key") === this.state.focusedBlocks[0]);
    const moveIndex = direction === "up" ? index - 1 : index + 1;
    const movedBlock = blocks.splice(index, 1)[0];
    blocks.splice(moveIndex, 0, movedBlock);

    const newContentState = ContentState.createFromBlockArray(blocks, {});
    const newEditorState = EditorState.push(this.state.editorState, newContentState, "move-block");

    /// !! The SelectionState of newEditorState needs to updated to properly control focus post move !! //
    this.updateEditorState({
      newEditorState,
      updateSidebar: false,
      hasFocus: false,
      changedBlocks: [],
    });
    this.triggerSave();

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "Move block",
      label: direction,
    });
  }

  onDeleteBlock() {
    // if there's only one block left we will disallow a deletion, or if no block is focused
    if (!hasActiveBlock(this.state.editorState) || !this.state.focusedBlocks || !this.state.focusedBlocks.length) {
      return false;
    }

    let editorState = this.state.editorState;
    const { contentState } = getState(editorState);

    // close sidebar cos if we're focused on inline textrule it revert to preloader
    if (this.state.sideBarOpen) {
      this.handleCloseSideBar();
    }

    // make an array of blocks
    let blocks = contentState.getBlocksAsArray();

    // We need to find the next block to set focus on when delete is done.
    // We will store our next block to focus here
    let blockToFocusNext;
    let blockToFocusNextIfLastMultiple;

    // for storing our new content state
    let newContentState;
    let delBlocks = this.state.focusedBlocks;

    const block = contentState.getBlockForKey(this.state.focusedBlocks[0]);
    if (block.get("type") === "Table") {
      delBlocks = getAllTableCellsInRow(editorState, block, true);
    }

    let changedBlocks = this.state.changedBlocks;

    // For every block in focusedBlocks splice in the same block with added is_deleted prop for use in customBlockStyleFn
    // We also need to calculate which block to focus next
    delBlocks.forEach((blockKey, i) => {
      changedBlocks.push(blockKey);
      changedBlocks = [...new Set(changedBlocks)];

      // get deleted block index and the block itself
      const index = blocks.findIndex((item) => item.get("key") === blockKey);
      const blockByKey = contentState.getBlockForKey(blockKey);

      // update is_deleted state for deleted block
      const updatedBlock = blockByKey.setIn(["data", "is_deleted"], true);
      // and remove the block from the blocks array
      blocks.splice(index, 1, updatedBlock);

      // create a new content state from newly built array of blocks
      newContentState = ContentState.createFromBlockArray(blocks, {});

      // In cases where multiple blocks are selected it will be possible for blockToFocusNext to be one of the deleted blocks
      // this is a rare edge case when our multiple block selection includes the last block in the editor
      // we will later check for last block, as well as multiple blocks, and in this case instead use the block before the first block
      if (i === 0) {
        // will be null if selecting the first block, but this variable is only used when its the last block in the editor
        // blockToFocusNextIfLastMultiple = newContentState.getBlockBefore(blockKey);
        blockToFocusNextIfLastMultiple = getNotDeletedBlockBefore(newContentState, blockKey);
      }

      // This should only run if the last block in the selection
      if (i === this.state.focusedBlocks.length - 1) {
        // usually, we just get the block after the block about to be deleted
        // blockToFocusNext = newContentState.getBlockAfter(blockKey);
        blockToFocusNext = getNotDeletedBlockAfter(newContentState, blockKey);

        // Here we handle the case where we have selected the last block in the whole editor
        // blockToFocusNext, using getBlockAfter, will return null if its the last block
        // in which case we'll instead return the block before it
        if (!blockToFocusNext) {
          // check whether single or multiple blocks are selected at the end of the editor
          if (i > 0) {
            // If we have multiple blocks selected we need to choose the block before the *first* block, which we stored earlier
            blockToFocusNext = blockToFocusNextIfLastMultiple;
          } else {
            // For a single block, simply get the block beforehand
            // blockToFocusNext = newContentState.getBlockBefore(blockKey);
            blockToFocusNext = getNotDeletedBlockBefore(newContentState, blockKey);
          }
        }
      }
    });

    // if no block to focus next then we must be at the end of the array and should not allow a deletion
    if (!blockToFocusNext) {
      return null;
    }

    // Get the key of the block to focus next
    const blockToFocusNextKey = blockToFocusNext.getKey();

    // create a new editor state by pushing the modified content state
    const newEditorState = EditorState.push(editorState, newContentState, "change-block-data");

    // For the new selection, we will simply set a basic 0 length selection at the start of the chosen block
    const selectionState = newEditorState.getSelection();
    const newSelectionState = selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
      anchorKey: blockToFocusNextKey,
      focusKey: blockToFocusNextKey,
    });

    // create new editor state combining our new editor state and new selection state
    const newEditorSelectionState = EditorState.forceSelection(newEditorState, newSelectionState);

    // trigger an update
    this.onChange(newEditorSelectionState, changedBlocks);

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "Delete block",
    });
  }

  onDuplicateBlock() {
    if (!this.state.focusedBlocks || !this.state.focusedBlocks.length) {
      return;
    }

    // close sidebar cos if we're focused on inline textrule it revert to preloader
    if (this.state.sideBarOpen) {
      this.handleCloseSideBar();
    }

    const { blocks, focusKey: newBlockKey } = duplicateBlock(this.state.editorState, this.state.focusedBlocks);
    const newContentState = ContentState.createFromBlockArray(blocks, {});

    // guessing with EditorChangeType insert-fragment it doesnt seem to make a difference
    // The SelectionState of newEditorState needs to updated to properly control focus post duplicate !! //
    const newEditorState = EditorState.push(this.state.editorState, newContentState, "insert-fragment");

    // For the new selection, we will simply set a basic 0 length selection at the start of the duplicated block
    const selectionState = newEditorState.getSelection();
    const newSelectionState = selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
      anchorKey: newBlockKey,
      focusKey: newBlockKey,
    });

    // create new editor state combining our new editor state and new selection state
    const newEditorSelectionState = EditorState.forceSelection(newEditorState, newSelectionState);

    this.updateEditorState({
      newEditorState: newEditorSelectionState,
      focusedBlocks: [newBlockKey],
      changedBlocks: [],
    });
    this.triggerSave();

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "Duplicate block",
    });
  }

  onMoveUpBlock = () => {
    if (!this.state.focusedBlocks || !this.state.focusedBlocks.length) {
      return;
    }

    const { blocks, focusKey } = moveUpBlock(this.state.editorState, this.state.focusedBlocks);
    const newContentState = ContentState.createFromBlockArray(blocks, {});

    // guessing with EditorChangeType insert-fragment it doesnt seem to make a difference
    // The SelectionState of newEditorState needs to updated to properly control focus post duplicate !! //
    const newEditorState = EditorState.push(this.state.editorState, newContentState, "insert-fragment");

    // For the new selection, we will simply set a basic 0 length selection at the start of the duplicated block
    const selectionState = newEditorState.getSelection();
    const newSelectionState = selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
      anchorKey: focusKey,
      focusKey: focusKey,
    });

    // create new editor state combining our new editor state and new selection state
    const newEditorSelectionState = EditorState.forceSelection(newEditorState, newSelectionState);

    this.updateEditorState({
      newEditorState: newEditorSelectionState,
      focusedBlocks: [focusKey],
      changedBlocks: [],
    });
    this.triggerSave();

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "MoveUp block",
    });
  };

  onMoveDownBlock() {
    if (!this.state.focusedBlocks || !this.state.focusedBlocks.length) {
      return;
    }

    const { blocks, focusKey } = moveDownBlock(this.state.editorState, this.state.focusedBlocks);
    const newContentState = ContentState.createFromBlockArray(blocks, {});

    // guessing with EditorChangeType insert-fragment it doesnt seem to make a difference
    // The SelectionState of newEditorState needs to updated to properly control focus post duplicate !! //
    const newEditorState = EditorState.push(this.state.editorState, newContentState, "insert-fragment");

    // For the new selection, we will simply set a basic 0 length selection at the start of the duplicated block
    const selectionState = newEditorState.getSelection();
    const newSelectionState = selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
      anchorKey: focusKey,
      focusKey: focusKey,
    });

    // create new editor state combining our new editor state and new selection state
    const newEditorSelectionState = EditorState.forceSelection(newEditorState, newSelectionState);

    this.updateEditorState({
      newEditorState: newEditorSelectionState,
      focusedBlocks: [focusKey],
      changedBlocks: [],
    });
    this.triggerSave();

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "MoveDown block",
    });
  }

  onUnlockModalShow() {
    ReactTooltip.hide();
    const modal = this.state.modal;
    this.setState({ modal: { ...modal, unlockBlock: true } });
  }

  onUnlockModalClose() {
    const modal = this.state.modal;
    this.setState({ modal: { ...modal, unlockBlock: false } });
  }

  onUnlockBlock() {
    this.onUnlockModalClose();
    unlockBlock.call(this);
  }

  /* Entity creation/editing and removal methods */

  // Create a new EditorState with a answer entity added and feed to onChange
  // which will handle updates to is is_modified and inline_textrules and triggering of save
  onInsertAnswer(meta) {
    const editorState = this.state.editorState;
    const { selectionState } = getState(editorState);
    const updatedEditorState = insertEntity.call(this, EntityTypes.ANSWER, meta, editorState, selectionState);
    updatedEditorState && this.onChange(updatedEditorState);
  }

  // Insert DocuSign tab
  onInsertDocusignTab(meta) {
    const editorState = this.state.editorState;
    const { selectionState } = getState(editorState);
    const updatedEditorState = insertEntity.call(this, EntityTypes.DOCUSIGN_TAB, meta, editorState, selectionState);
    updatedEditorState && this.onChange(updatedEditorState);
  }

  onInsertTextrule() {
    this.setState({
      creatingTextrule: true,
    });
    const editorState = this.state.editorState;
    const { contentState, selectionState } = getState(editorState);

    const isShouldBlock = isShouldBlockLevel(this.state.focusedBlocks, contentState, selectionState);

    // TO DO triage to different types of textrule functions ie insertInlineTextrule
    if (!isShouldBlock) {
      const blockByKey = contentState.getBlockForKey(this.state.focusedBlocks[0]);
      insertInlineTextrule.call(this, contentState, selectionState, blockByKey);
    } else {
      const blockIds = this.state.focusedBlocks.map((item) => {
        return contentState.getBlockForKey(item).getIn(["data", "id"]);
      });

      // This will prevent Chrome expanding selection to next block on 3 clicks in block
      let newSelectionState = selectionState;
      if (
        this.state.focusedBlocks.length === 1 &&
        selectionState.anchorKey !== selectionState.focusKey &&
        selectionState.focusOffset === 0
      ) {
        newSelectionState = selectionState.merge({
          focusKey: selectionState.anchorKey,
        });
      }

      insertBlockTextrule.call(this, contentState, newSelectionState, blockIds, this.state.focusedBlocks);
    }
  }

  // Cannot move this into ./lib/textrules due to this binding issues as this is passed down to BaseRuleGroup as a handler
  // blockKey : sometimes we need to unlink textrule from the old focused block
  // blockKey means the old focused block and it may be null
  onDeleteTextrule(textrule, blockKey) {
    this.props.textrulesStore
      .delete({
        deletedTextrule: {
          id: textrule.id,
        },
      })
      .then((/* res */) => {
        const textruleCustomStyle = `TEXTRULE_${textrule.id}`;
        const textruleActiveCustomStyle = `TEXTRULEACTIVE_${textrule.id}`;
        const existingStyles = this.state.customStyleMap;
        delete existingStyles[textruleCustomStyle];
        delete existingStyles[textruleActiveCustomStyle];
        this.setState({ customStyleMap: existingStyles });

        unlinkTextrule.call(this, textrule.id, this.state.editorState, this.state.focusedBlocks, blockKey);
        this.handleCloseSideBar();
      });

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Editing",
      action: "Delete rule",
      label: "Inline rule",
    });
  }

  onUnlinkTextrule(textruleId) {
    unlinkTextrule.call(this, textruleId, this.state.editorState, this.state.focusedBlocks);
  }

  onInsertLoop() {
    markLoopingBlock.call(this, this.state.editorState, true);
  }

  onDeleteLoop() {
    markLoopingBlock.call(this, this.state.editorState, false);
    this.handleCloseSideBar();
  }

  legacyBrowserMessage() {
    if (this.state.browser.name === "ie") {
      return (
        <Banner warning>
          <p>
            Yikes, we&apos;ve detected that you&apos;re using Internet Explorer. For a better experience building bots
            on Josef, we recommend using another browser.{" "}
            <a
              href="https://support.joseflegal.com/hc/en-us/articles/360060065614-Can-I-access-Josef-with-Internet-Explorer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more here.
            </a>
          </p>
        </Banner>
      );
    } else {
      return null;
    }
  }

  goToHelpCentre() {
    var win = window.open(
      "https://support.joseflegal.com/hc/en-us/articles/360040771054-Creating-a-document-in-the-Word-editor",
      "_blank"
    );
    win.focus();

    // Send a request to Google Analystics to register this event
    gaEvent({
      category: "Navigation",
      action: "Visit help centre",
    });
  }

  triggerSave(data = null) {
    // A change to state has been made, and we are trying to initiate a save!
    // We first need to register that there may be an unsaved change in the editor session
    // this is used to warn the user if save hasn't completed and the user tries to close their browser window
    // See: beforeunload event
    this.setState({
      isSaved: false,
    });

    // trigger the save (on a debounced timer)
    if (data) {
      this.onSaveEditor(data);
    } else {
      this.debounceSave();
    }
  }

  debounceSave = debounce(() => {
    this.onSaveEditor();
  }, 1000);

  onGoToFirstUnsupportedBlock() {
    goToFirstUnsupportedBlock.call(this, this.state.editorState);
  }

  cancelBlockStyleCopy = (/* e */) => {
    const clipboard = this.state.clipboard;
    this.setState({ clipboard: { ...clipboard, key: null } });
    document.getElementById("container-root").classList.remove("painter-selected");
  };

  renderDocumentCards() {
    return exportFormatOptions.map((item) => {
      return (
        <div className="document-card" key={`document-card-${item.id}`}>
          <div className="document-card__content">
            <span className={`document-card__${item.extension.toLowerCase()}-icon`}>
              <JoIcon icon={item.extension} isLarge />
            </span>
            <div className="document-card__heading">
              <h4 className={classNames({ truncate: this.state.name.length > 32 })}>
                {this.state.name}.{item.extension.toLowerCase()}
              </h4>
              <span className="document-card__info small-text">{item.label}</span>
            </div>
          </div>
          <div className="document-card__action">
            <JoLink iconLeft="DownloadArrow" clickHandler={() => this.onPreview(item.id)}>
              Download
            </JoLink>
          </div>
        </div>
      );
    });
  }

  render() {
    const debugOn =
      (import.meta.env.DEV && import.meta.env.VITE_APP_DEBUG === "true") || this.props.debugOn;

    const { selectedTextruleId, selectedLoop, focusedBlocks } = this.state;
    const { isDisabled } = this.props;

    let debugTextruleData = null;
    //get textruleData for debugWindow
    if (debugOn && selectedTextruleId) {
      debugTextruleData = getSelectedInlineTextruleData(this.state.editorState);
    }
    const { variables } = this.props;

    const { contentState, selectionState } = getState(this.state.editorState);
    const blockByKey = focusedBlocks ? contentState.getBlockForKey(focusedBlocks[0]) : null;

    const docusignStatus = this.props.recipientsStore.docusignStatus;

    // disable answer/textrule button based on selecton range content and !focusedBlocks
    const disableTextruleButton = isDisabled
      ? Boolean(!selectedTextruleId)
      : getIsTextruleBtnDisabled(
          this.state.editorState,
          focusedBlocks,
          blockByKey,
          selectedTextruleId,
          variables,
          this.state.creatingTextrule
        );

    const disableAnswerButton =
      getIsAnswerBtnDisabled(
        variables,
        focusedBlocks,
        blockByKey,
        hasInlineStyleInRange(selectionState, blockByKey, /^TEXTRULE/)
      ) || isDisabled;

    // disable loop button based on selecton range content and !focusedBlocks
    const disableLoopButton = getIsLoopBtnDisabled(variables, focusedBlocks, blockByKey) || isDisabled;

    // construct our dashboard url
    // TODO find the issue number & question number that the document is attached to
    // so that we can send users back to a relevant part of the app
    const dashboardUrl = getDashboardURL() + "/#/issues/" + this.props.issueId + "/questions/" + this.props.questionId;

    return (
      <Fragment>
        {debugOn && (
          <DebugInfo
            blockByKey={blockByKey}
            selectionState={selectionState}
            selectedTextruleId={selectedTextruleId}
            selectedLoop={selectedLoop}
            debugTextruleData={debugTextruleData}
            contentState={contentState}
            onClick={this.cancelBlockStyleCopy}
          />
        )}
        <div onClick={this.cancelBlockStyleCopy}>
          <Navbar
            toolbar={
              <Toolbar>
                <JoTooltip id="bottom" position="bottom"></JoTooltip>
                <JoTooltip id="left" position="left"></JoTooltip>
                <JoToolbarButton
                  data-tip="Undo"
                  data-for="bottom"
                  icon="Undo"
                  clickHandler={this.onUndoClick}
                  disabled={this.state.editorState.getUndoStack().isEmpty()}
                  testId="undo"
                />
                <JoToolbarButton
                  data-tip="Redo"
                  data-for="bottom"
                  icon="Redo"
                  clickHandler={this.onRedoClick}
                  disabled={this.state.editorState.getRedoStack().isEmpty()}
                  testId="redo"
                />
                <JoToolbarButton
                  disabled={
                    getIsInlineStylesBtnDisabled(this.state.editorState, focusedBlocks) || this.props.isDisabled
                  }
                  data-tip="Bold"
                  data-for="bottom"
                  icon="TextBold"
                  title="Bold"
                  clickHandler={this.onBoldClick}
                  active={this.state.toolbar.BOLD}
                  testId="bold"
                />
                <JoToolbarButton
                  disabled={
                    getIsInlineStylesBtnDisabled(this.state.editorState, focusedBlocks) || this.props.isDisabled
                  }
                  data-tip="Italic"
                  data-for="bottom"
                  icon="TextItalic"
                  title="Italic"
                  clickHandler={this.onItalicClick}
                  active={this.state.toolbar.ITALIC}
                  testId="italic"
                />
                <JoToolbarButton
                  disabled={
                    getIsInlineStylesBtnDisabled(this.state.editorState, focusedBlocks) || this.props.isDisabled
                  }
                  data-tip="Underline"
                  data-for="bottom"
                  icon="TextUnderline"
                  title="Underline"
                  clickHandler={this.onUnderlineClick}
                  active={this.state.toolbar.UNDERLINE}
                  testId="underline"
                />

                <JoToolbarButton
                  data-tip="Copy text formatting"
                  disabled={blockStyleCopyIsBtnDisabled.call(this)}
                  icon="BlockStyleCopy"
                  data-for="bottom"
                  title="Copy text formatting"
                  clickHandler={this.onCopyBlockStyleClick}
                  active={blockStyleCopyIsBtnActive.call(this)}
                  isStyleCopyBtn={true}
                  testId="copy-text-formatting"
                />
                <p className="navbar__saving">
                  <JoIcon title="save" icon={this.state.saveButton.icon} spacing="right" />
                  {this.state.saveButton.text}
                </p>
                {this.state.unsupportedCount > 0 && (
                  <JoPopupMenu
                    defaultOpen={true}
                    lockCount={this.state.unsupportedCount}
                    corruptCount={0}
                    goToFirstUnsupportedBlock={this.onGoToFirstUnsupportedBlock}
                  />
                )}
              </Toolbar>
            }
          >
            {this.legacyBrowserMessage()}
            <NavbarGroup classList={["navbar__group--absolute-left"]}>
              <a
                className="navbar__back"
                href={dashboardUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.handleBackToDashboard}
                data-test-id="back-to-dashboard"
              >
                <Logo />
              </a>

              {this.props.documentStore.name && (
                <div className="document-name-wrapper">
                  <input
                    className="document-name-input truncate"
                    value={this.state.name}
                    placeholder="Document title"
                    onChange={this.handleUpdateName}
                  />
                </div>
              )}

              {this.props.isDisabled && (
                <div className="view-only-wrapper">
                  <CtaMessageBar testId="view-only-status-bar">
                    View only
                    <span
                      data-tip="While a bot is submitted for approval you are unable to modify its content."
                      data-for="bottom"
                    >
                      <JoIcon title="info" icon="InformationCircle" spacing="left" />
                    </span>
                  </CtaMessageBar>
                </div>
              )}
            </NavbarGroup>

            <NavbarGroup classList={["navbar__group--absolute-center"]}>
              <JoButton
                data-tip="Insert a user response or calculation from available variables."
                data-for="bottom"
                flush="bottom"
                disabled={disableAnswerButton}
                buttonType="Editor"
                size="slim"
                clickHandler={() => this.handleOpenSideBar("answer")}
                testId="insert-variable"
              >
                <JoIcon title="Insert variable" icon="VariableX" spacing="right" />
                Insert variable
              </JoButton>

              <JoButton
                data-tip={
                  selectionState.isCollapsed()
                    ? "Apply a rule to determine when the block of text will appear in your document."
                    : "Apply a rule to determine when the highlighted text will appear in your document."
                }
                data-for="bottom"
                flush="bottom"
                disabled={disableTextruleButton}
                buttonType="Editor"
                size="slim"
                clickHandler={this.onClickRule}
                variant={selectedTextruleId ? "selected" : null}
                testId="edit-rule"
              >
                <JoIcon icon="Cog1" spacing="right" />
                {this.getInsertTextruleBtnLabel()}
              </JoButton>

              <JoButton
                data-tip={"Repeat this block if an inserted variable is given more than once."}
                data-for="bottom"
                flush="bottom"
                disabled={disableLoopButton}
                buttonType="Editor"
                size="slim"
                clickHandler={this.onClickLoop}
                variant={selectedLoop ? "selected" : null}
                testId="edit-looping"
              >
                <JoIcon icon={selectedLoop ? "Loop" : "Loop"} spacing="right" />
                {this.getInsertLoopBtnLabel()}
              </JoButton>
            </NavbarGroup>

            <NavbarGroup classList={["navbar__group--absolute-right"]}>
              <JoButton
                disabled={this.state.isSaving}
                flush="all"
                variant={"outline"}
                size="slim"
                clickHandler={() => this.handleOpenSideBar("settings")}
              >
                <JoIcon title="Export" icon={"CommonFileTextSettings"} spacing="right" />
                Settings
              </JoButton>
            </NavbarGroup>
          </Navbar>
        </div>

        <div className={this.state.sideBarOpen ? "sidebar sidebar__wrapper__opened" : "sidebar__wrapper"}>
          <DocusignRecipientsButton docusignStatus={docusignStatus} handleOpenSideBar={this.handleOpenSideBar} />
          <SideBar
            noOverlay
            customBurgerIcon={false}
            outerContainerId={"App"}
            isOpen={this.state.sideBarOpen}
            closeSideBarHandler={this.handleCloseSideBar}
          >
            {this.state.sideBarContent === "answer" && (
              <InsertAnswer
                onSubmit={this.onInsertAnswer}
                closeSideBarHandler={this.handleCloseSideBar}
                connectedVariables={variables}
              />
            )}
            {this.state.sideBarContent === "recipients" && (
              <DocuSignRecipientSidebar
                key={this.state.DocuSignRecipientKey}
                variables={variables}
                onSubmit={this.onInsertDocusignTab}
                closeSideBarHandler={this.handleCloseSideBar}
              />
            )}
            {this.state.sideBarContent === "textrule" &&
              (this.state.selectedTextruleId &&
              this.props.textrulesStore.textruleById(this.state.selectedTextruleId) ? (
                <BaseRuleGroup
                  isDisabled={this.props.isDisabled}
                  textruleId={this.state.selectedTextruleId}
                  textrule={this.props.textrulesStore.textruleById(this.state.selectedTextruleId)}
                  variables={variables}
                  deleteTextruleHandler={this.onDeleteTextrule}
                  unlinkTextruleHandler={this.onUnlinkTextrule}
                  blockByKey={blockByKey}
                />
              ) : (
                <Preloader inverted title="Loading rules&hellip;" />
              ))}
            {this.state.sideBarContent === "loop" && (
              <div>
                <h2 className="space-below">Edit looping</h2>
                <p>
                  This block will be repeated if the inserted responses are given more than once. The same styling will
                  apply to the repeated blocks.
                </p>
                <JoLink iconLeft="Bin" clickHandler={this.onDeleteLoop} testId="delete-looping">
                  Delete looping
                </JoLink>
              </div>
            )}
            {this.state.sideBarContent === "settings" && (
              <div className="settings-sidebar">
                <h2 className="space-below">Document settings</h2>
                <hr className="sidebar__divider" />
                <div className="settings-sidebar__section">
                  <h3>Document format</h3>
                  <p>Select the document format your users will download.</p>
                  <JoSelectableCards
                    options={exportFormatOptions.map((v) => v.label)}
                    selectHandler={this.handleToggleExportFormat}
                    value={[exportFormatOptions.findIndex((v) => v.id === this.state.documentFormatId)]}
                  />
                </div>
                <hr className="sidebar__divider" />
                <div className="settings-sidebar__section">
                  <h3>Document access</h3>
                  <p>Set a limit for how long your document remains accessible.</p>
                  <div className="settings-sidebar__toggle-wrapper">
                    <ToggleSwitch
                      switchState={this.state.expires}
                      changeHandler={this.handleExpire}
                      disabled={false}
                      onHandleColor={Color.neutral}
                      onColor={Color.purple}
                    />
                    <span>Expires after 45 days</span>
                  </div>
                </div>
                {this.props.authStore.valuesById("docusign_base_uri") && this.props.authStore.showDocusign && (
                  <div>
                    <hr className="sidebar__divider" />
                    <div className="settings-sidebar__section">
                      <h3>DocuSign integration</h3>
                      <div className="settings-sidebar__toggle-wrapper">
                        <ToggleSwitch
                          switchState={this.state.sendToDocusign}
                          changeHandler={this.handleSendToDocusign}
                          disabled={false}
                          onHandleColor={Color.neutral}
                          onColor={Color.purple}
                        />
                        <span>Send document to DocuSign</span>
                      </div>
                    </div>
                  </div>
                )}
                <hr className="sidebar__divider" />
                <div className="settings-sidebar__section">
                  <div className="settings-sidebar__section-heading-wrapper">
                    <h3>Export document</h3>
                    {/* <JoLink
                      href="https://support.joseflegal.com/hc/en-us/articles/5665173827727"
                      target="_blank"
                      iconLeft="InformationCircle"
                    >
                      Learn more
                    </JoLink> */}
                  </div>
                  <p>Export your document with automations to PDF or Microsoft Word.</p>
                  {/* <p>
                    You can edit your document in Microsoft Word and re-upload it to this bot without losing any of your
                    automations.
                  </p> */}
                  <div className="settings-sidebar__doc-export-wrapper">{this.renderDocumentCards()}</div>
                </div>
              </div>
            )}
          </SideBar>
        </div>
        <section
          id="page-wrap"
          className={this.state.browser.name === "ie" ? "main is-padded" : "main"}
          onClick={this.cancelBlockStyleCopy}
        >
          <div id="editor-position" className="editor-position">
            <div id="editor-container" className="editor-container" onClick={this.onFocus}>
              {!this.props.isDisabled && (
                <BlockMenu element={this.state.focusedBlockElement}>
                  <JoToolbarButton
                    data-tip="Move up"
                    data-for="left"
                    purple
                    icon="ArrowUp1"
                    disabled={
                      getIsMoveBtnDisabled(this.state.editorState, this.state.focusedBlocks, "up") ||
                      this.props.isDisabled
                    }
                    clickHandler={this.onMoveUpBlock}
                    testId="move-up"
                  />
                  <JoToolbarButton
                    data-tip="Move down"
                    data-for="left"
                    purple
                    icon="ArrowDown1"
                    disabled={
                      getIsMoveBtnDisabled(this.state.editorState, this.state.focusedBlocks, "down") ||
                      this.props.isDisabled
                    }
                    clickHandler={this.onMoveDownBlock}
                    testId="move-down"
                  />
                  <JoToolbarButton
                    data-tip="Delete block"
                    data-for="left"
                    purple
                    disabled={!hasActiveBlock(this.state.editorState) || this.props.isDisabled}
                    icon="Bin"
                    clickHandler={this.onDeleteBlock}
                    testId="delete-block"
                  />
                  <JoToolbarButton
                    data-tip="Duplicate block"
                    data-for="left"
                    purple
                    icon="CommonFileDouble2"
                    disabled={(focusedBlocks && focusedBlocks.length > 1) || this.props.isDisabled}
                    clickHandler={this.onDuplicateBlock}
                    testId="duplicate-block"
                  />
                  {isUnsupportedBlock(this.state.editorState, this.state.focusedBlocks) && (
                    <JoToolbarButton
                      data-tip="Unlock unsupported block"
                      data-for="left"
                      purple
                      icon="Lock1"
                      iconHover="Unlock1"
                      clickHandler={this.onUnlockModalShow}
                      testId="unlock-unsupported-block"
                    />
                  )}
                </BlockMenu>
              )}

              <UnlockBlockModal
                show={this.state.modal.unlockBlock}
                handleOk={this.onUnlockBlock}
                handleClose={this.onUnlockModalClose}
              />

              <div id="container-root" className="container-root" onClick={(e) => e.stopPropagation()}>
                <Editor
                  ref={this.setDomEditorRef}
                  onBlur={this.onBlur}
                  editorState={this.state.editorState}
                  decorators={this.generateDecorators()}
                  onChange={this.onChange}
                  handleKeyCommand={this.handleKeyCommand}
                  keyBindingFn={keyBindingFn.bind(this)}
                  blockRenderMap={extendedBlockRenderMap}
                  blockStyleFn={this.customBlockStyleFn}
                  customStyleMap={this.state.customStyleMap}
                  handlePastedText={this.handlePastedText}
                  blockRendererFn={customBlockRenderer.bind(this)}
                  handleBeforeInput={handleBeforeInput.bind(this)}
                />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

AutomationEditor.propTypes = {
  isDisabled: PropTypes.bool,
  documentId: PropTypes.number.isRequired,
  debugOn: PropTypes.bool,
  documentUuid: PropTypes.string,
  data: PropTypes.object,
  questions: PropTypes.array,
  variables: PropTypes.array,
  questionId: PropTypes.number,
  issueId: PropTypes.number,
  textrulesStore: PropTypes.any,
  documentFormatId: PropTypes.number,
  updateDocument: PropTypes.func,
  constantsStore: PropTypes.object,
  documentName: PropTypes.string,
  authStore: PropTypes.object,
};

AutomationEditor.defaultProps = {
  debugOn: false,
  isDisabled: false,
};

// mobx stores are injected into AutomationEditor so we can access them on component props
export default inject(
  "textrulesStore",
  "ruleGroupsStore",
  "constantsStore",
  "documentStore",
  "recipientsStore",
  "authStore"
)(AutomationEditor);

import { observable, action, computed, makeObservable } from "mobx";
import api from "../services/api";

class RecipientsStore {
  envelopeTemplateId = null;
  documentId = null;
  isSigningOrderEnabled = null;
  recipients = [];
  docusignContactRecipients = [];
  isDocumentMarkedForDocusign = false;
  activeRecipient = null; //to keep track of active recipient
  docusignTabs = [];
  envelopeAutoSend = null;

  /* Setting the recipients array to the value passed in. */
  setRecipients(recipients) {
    this.recipients = recipients;
  }

  setActiveRecipients(key) {
    this.activeRecipient = key;
  }

  setDocusignStatus(status) {
    this.isDocumentMarkedForDocusign = status;
  }

  setEnvelopeTemplateId(id) {
    this.envelopeTemplateId = id;
  }

  setEnvelopeAutoSend(value) {
    this.envelopeAutoSend = value.enable_auto_send;
  }

  setSigningOrderEnabled(value) {
    this.isSigningOrderEnabled = value.enable_signing_order;
  }

  setData(envelopeData) {
    this.envelopeTemplateId = envelopeData.id;
    this.documentId = envelopeData.requested_document_id;
    this.isSigningOrderEnabled = envelopeData.enable_signing_order;
    this.recipients = envelopeData.recipients;
    this.docusignTabs = envelopeData.tabs;
    this.envelopeAutoSend = envelopeData.enable_auto_send;
  }

  resetData() {
    this.envelopeTemplateId = null;
    this.documentId = null;
    this.isSigningOrderEnabled = null;
    this.recipients = [];
    this.docusignTabs = [];
    this.envelopeAutoSend = null;
  }

  updateSigningOrderEnabled(value) {
    const id = this.envelopeTemplateId;
    const originalValue = this.isSigningOrderEnabled;
    this.setSigningOrderEnabled(value);

    return api.envelope_template
      .update(id, value)
      .then((template) => {
        return template;
      })
      .catch((err) => {
        // revert back to original value
        this.setSigningOrderEnabled(originalValue);
        return err;
      });
  }

  updateDocusignEnvelopeAutoSend(value) {
    const id = this.envelopeTemplateId;
    const originalValue = this.envelopeAutoSend;
    this.setEnvelopeAutoSend(value);
    return api.envelope_template
      .update(id, value)
      .then((template) => {
        return template;
      })
      .catch((err) => {
        // revert back to original value
        this.setEnvelopeAutoSend(!originalValue);
        return err;
      });
  }

  get allRecipients() {
    return this.recipients.slice();
  }

  get currentActiveRecipient() {
    return this.activeRecipient;
  }

  get signingOrderStatus() {
    return this.isSigningOrderEnabled;
  }

  get envelopeTempId() {
    return this.envelopeTemplateId;
  }

  get docusignStatus() {
    return this.isDocumentMarkedForDocusign;
  }

  get recipientsLength() {
    return this.recipients.length;
  }

  /**
   * Returns the contacts from the DocuSign API.
   * If already fetched before, API is not called again to avoid performance issues
   * @returns The docusignContacts property is being returned.
   */
  get docusignContacts() {
    if (this.docusignContactRecipients.length) return this.docusignContactRecipients;

    const documentId = this.documentId;
    return api.envelope_template_recipient
      .getFromDocusign(documentId)
      .then((contacts) => {
        this.docusignContactRecipients = contacts;
        return this.docusignContactRecipients;
      })
      .catch((err) => {
        return err;
      });
  }

  add(recipient) {
    this.recipients.push(recipient);
  }

  create(recipient, index, key) {
    return api.envelope_template_recipient.create(recipient).then((recipient) => {
      // We need to reset this key to avoid react re-render and glitch Collapsible
      recipient.key = key;
      // replacing the placeholder recipient
      this.recipients[index] = recipient;
      this.activeRecipient = recipient.id;
      return recipient;
    });
  }

  createTab(tab) {
    // Adding the document id and the recipient id to the tab
    const tabAnchor = tab.anchor;
    // check that tab anchor doesn't already exist in the backend
    const tabExists = this.docusignTabs.find((tab) => tab.anchor === tabAnchor);

    if (tabExists) return;

    tab.document_id = this.documentId;
    tab.envelope_template_id = this.envelopeTemplateId;

    return api.envelope_template_tab.create(tab).then((tab) => {
      this.docusignTabs.push(tab);
      return tab;
    });
  }

  updateOne(recipient, key) {
    return api.envelope_template_recipient.update(recipient).then((recipient) => {
      const index = this.recipients.findIndex((rec) => rec.id === recipient.id);
      recipient.key = key;
      this.recipients[index] = recipient;

      return recipient;
    });
  }

  delete(recepientId) {
    return api.envelope_template_recipient.remove(recepientId).then((res) => {
      if (res === 204) {
        // Removing from store if delete was successful
        const recipientToRemove = this.recipients.find((recipient) => recipient.id === recepientId);
        this.recipients.remove(recipientToRemove);

        // We want to disable signing order when the number of recipients go below 2
        if (this.recipientsLength < 2) {
          this.updateSigningOrderEnabled({
            enable_signing_order: false,
          });
        }

        return res;
      }
    });
  }

  // only use this to delete from local state - i.e. recipients which are not yet added to DB
  deleteLocal(index) {
    this.recipients.splice(index, 1);
    if (this.recipientsLength < 2) {
      this.updateSigningOrderEnabled({
        enable_signing_order: false,
      });
    }
  }

  constructor() {
    makeObservable(this, {
      recipients: observable,
      isSigningOrderEnabled: observable,
      envelopeAutoSend: observable,
      activeRecipient: observable,
      allRecipients: computed,
      recipientsLength: computed,
      setActiveRecipients: action,
      setRecipients: action,
      setDocusignStatus: action,
      setData: action,
      resetData: action,
      setEnvelopeTemplateId: action,
      create: action,
      createTab: action,
      updateOne: action,
      updateSigningOrderEnabled: action,
      updateDocusignEnvelopeAutoSend: action,
      delete: action,
      deleteLocal: action,
      add: action,
    });
  }
}

export default new RecipientsStore();
